<template>
  <div class="NewsDetails">
    <div class="NewsInfo banner">
      <div class="News-title">{{DetailsInfo.newsTitle}}</div>
      <div class="News-data">
        <div class="day">发布时间：{{formatDate(DetailsInfo.createTime)}}</div>
        <span></span>
        <div class="look">浏览次数：{{DetailsInfo.readNum}}</div>
      </div>
      <div class="v-html">
        <!-- Html -->
        <Vhtml :Html="DetailsInfo.newsContents" />
      </div>
      <!-- <div class="title-img" v-if="DetailsInfo.title_img_url">
        <img :src="DetailsInfo.title_img_url" alt />
      </div>
      <div class="operate">
        <div class="end">
          <div>雇人佣运营团队</div>
          <div>{{formatDate(DetailsInfo.update_time)}}</div>
        </div>
      </div>-->
    </div>
  </div>
</template>
<script>
import Vhtml from "@/components/vHtml";
export default {
  data() {
    return {
      DetailsInfo: "",
    };
  },
  components: {
    Vhtml,
  },
  created() {
    this.GetDetailsInfo();
  },
  methods: {
    // 获取公告详情
    GetDetailsInfo() {
      let url = "/app-api/hiring/news/get";
      let Ids = `?id=${this.$route.query.id}`;
      this.$axios.get(url + Ids).then((res) => {
        if (res.data.code === 0) {
          this.DetailsInfo = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.NewsDetails {
  background: #f7f9fa;
  padding-top: 30px;
  padding-bottom: 30px;
  .NewsInfo {
    background: #ffffff;
    padding: 60px 100px;
    box-sizing: border-box;
    .News-title {
      font-weight: 400;
      color: #000000;
      line-height: 33px;
      font-size: 28px;
      text-align: center;
    }
    .News-data {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      .day,
      .look {
        font-weight: 400;
        color: #999999;
        line-height: 19px;
        font-size: 16px;
      }
      span {
        width: 1px;
        height: 14px;
        background: #d9d9d9;
        margin: 0 4px;
      }
    }
    .v-html {
      margin-top: 60px;
    }
    .title-img {
      margin-top: 40px;
    }
    .operate {
      margin-top: 235px;
      display: flex;
      justify-content: flex-end;
      .end {
        div {
          font-weight: 400;
          color: #000000;
          line-height: 32px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>
